<template>
  <div class="flex flex-row gap-2 items-center justify-center md:justify-end w-full text-right pb-2.5 mb-7 mx-4">
    <DsCheckbox
      id="grvCheckbox"
      :model-value="modelValue"
      @update:model-value="$emit('update:modelValue', $event)"
    />
    <label for="grvCheckbox" class="inline-block">
      <span v-if="optional">Freiwillige</span> Einzahlung in die GRV
    </label>
  </div>
</template>

<script setup lang="ts">
import { DsCheckbox } from '@demvsystems/design-components';

defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  optional: {
    type: Boolean,
    default: false,
  },
});
</script>
