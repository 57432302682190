import { defineStore } from 'pinia';
import { getActions } from '@/api/actions/actionsApi';
import type { Action, InteractionStatus } from '@/api/actions/types';
import { computed, ref } from "vue";
import { groupBy } from "lodash-es";

export const useHandlungStore = defineStore('handlung', () => {
  const actions = ref<Map<number, Action>>(new Map());
  const initialized = ref<boolean>(false);
  const loading = ref<boolean>(false);
  const errorMsg = ref<string|null>(null);

  const initializing = computed<boolean>(() => !initialized.value || loading.value);
  const actionsByState = computed<Partial<Record<InteractionStatus, Action[]>>>(() => {
    return groupBy<Action>(Array.from(actions.value.values()), (a) => a.interaction_status);
  });

  const priorityAction = computed<null|Action>(() => actionsByState.value.open?.reduce(
    (max: null|Action, action: Action) => action.priority > (max?.priority ?? 0) ? action : max, null,
  ) ?? null);

  function getActionById(id: number): Action|null {
    return actions.value.get(id) ?? null;
  }

  function updateAction(action: Action): void {
    actions.value.set(action.id, action);
  }

  function setActions(data: Action[]): void {
    actions.value.clear();
    data.forEach((action) => actions.value.set(action.id, action));
  }

  async function fetchActionsSliently() {
    const result = await getActions();
    if (result instanceof Error) return;

    setActions(result);
  }

  async function fetchActions() {
    loading.value = true;
    errorMsg.value = null;
    const result = await getActions();
    if (result instanceof Error) {
      errorMsg.value = "Unerwarteter Fehler beim Laden der Handlungen"
    } else {
      setActions(result);
    }
    loading.value = false;
    initialized.value = true;
  }

  function $reset() {
    actions.value.clear();
    loading.value = false;
    errorMsg.value = null;
  }

  return {
    actions,
    errorMsg,
    loading,
    initializing,

    actionsByState,
    priorityAction,

    $reset,
    updateAction,
    fetchActions,
    fetchActionsSliently,
    getActionById,
  }
});
