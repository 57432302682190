<script setup lang="ts">

import { DsIcon } from '@demvsystems/design-components';
import { useRoute, useRouter } from 'vue-router';
import { computed } from 'vue';
import { storeToRefs } from "pinia";
import { useBedarfStore } from "@/bedarf/store/bedarfStore";

const route = useRoute();
const router = useRouter();
const { verlauf } = storeToRefs(useBedarfStore());

const hideHeaderBackNav = computed(() => {
  if (route.meta.hideHeaderBackNav) return true;

  if (route.path === '/mein-bedarf/beratungsverlauf' && verlauf.value.length === 0) return true;

  const segments = route.path.split('/').filter(Boolean);
  return segments.length < 2;
});

const navigate = () => {
  const segments = route.path.split('/').filter(Boolean);
  if (hideHeaderBackNav.value) return true;

  return router.push({ path: `/${segments.slice(0, -1).join('/')}` });
};
</script>

<template>
  <button
    v-if="!hideHeaderBackNav"
    class="flex gap-2 rounded-full hover:bg-gray-100 items-center py-1 md:py-0.5 px-2 text-gray-900 -ml-2"
    @click="navigate"
  >
    <DsIcon name="arrow-left-long" variant="regular" />
    <span class="hidden md:block">zurück</span>
  </button>
</template>
