<script setup lang="ts">
import { DsIcon } from "@demvsystems/design-components";
</script>

<template>
  <div class="text-lg md:text-xl text-center lead text-muted py-5">
    <DsIcon
      class="text-secondary mr-2"
      name="spinner-third"
      variant="duotone"
      spin
      size="lg"
    />
    <slot />
  </div>
</template>
