<script setup lang="ts">
import { isAndroid, isIos, isNativePlatform } from '@/application/utils/envInfo';
import HeaderBackButton from '@/application/components/HeaderBackButton.vue';
import DesktopMenu from '@/application/menu/components/DesktopMenu.vue';
import DesktopMenuDropdown from '@/application/menu/components/DesktopMenuDropdown.vue';
import MobileHeaderMenu from '@/application/menu/components/MobileHeaderMenu.vue';

</script>

<template>
  <div class="bg-white shadow">
    <div
      class="grid grid-cols-[auto_1fr_auto] md:grid-cols-[1fr_4fr_1fr] items-center gap-2 md:container mx-4 pb-2 h-11 md:h-12"
      :class="{'pt-2': !isNativePlatform || isAndroid, 'pt-safe': isIos}"
    >
      <div>
        <HeaderBackButton class="mr-2" />
      </div>
      <div class="-ml-2">
        <MobileHeaderMenu />
        <DesktopMenu />
      </div>
      <div>
        <DesktopMenuDropdown />
      </div>
    </div>
  </div>
</template>
