<script setup lang="ts" generic="T extends ExternalLinkData">
import type { ActionBlockProps, ExternalLinkData } from '@/api/actions/types';
import { DsButton } from '@demvsystems/design-components';
import { logAction } from '@/api/actions/actionsApi';
import { useGet, call } from "@/api/lib/integration";
import { ref } from 'vue';
import { storeToRefs } from "pinia";
import { useBaseDataStore } from "@/user/store/baseDataStore.ts";

const props = defineProps<ActionBlockProps<T>>();

async function logExternalLink() {
  await logAction({
    actionId: props.actionId,
    context: props.baustein.type,
    data: props.baustein.data,
  });
}

const url = ref('');
const { isBroker } = storeToRefs(useBaseDataStore());

if (isBroker.value !== true) {
  call(
    useGet<void, { url: string }>(props.baustein.data.url)(),
    (result) => { url.value = result.url; },
  ).then();
}
</script>

<template>
  <DsButton
    :href="url"
    variant="secondary"
    external
    :disabled="isBroker || !url"
    class="my-3 mr-auto"
    @click="logExternalLink"
  >
    {{ baustein.data.label }}
  </DsButton>
</template>
